<template>
  <idp-spinner class="idp-spinner">
    <div id="global_spinner" class="idp-page-modal">
      <div id="spinner-message" class="idp-page-modal-message idp-modal-message-subtle">
        <div class="spa-spinner"></div>
        <div class="spa-spinner-text ng-binding">Loading...</div>
      </div>
    </div>
  </idp-spinner>
</template>

<style lang="scss" scoped>
.idp-spinner {
  margin-top: 40px;
}

.idp-page-modal {
  z-index: 99999 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
}

.idp-page-modal-message {
  z-index: 199999 !important;
  text-align: left;
  color: $black;
  padding: 10px 10px 10px 10px;
}

.idp-page-modal-message.idp-modal-message-subtle {
  margin: auto;
  width: 12.5rem;
  height: 3rem;
  border-radius: 0.25rem;
  position: absolute;
  background: white;
  box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.32);
  border: solid 1px rgba(0, 0, 0, 0.2);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.spa-spinner {
  position: relative;
}

.spa-spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: 2px;
  border-radius: 50%;
  border: 0.25rem solid #adb5bd;
  border-top-color: #94d500;
  animation: spinner 1s linear infinite;
}

.spa-spinner-text {
  font-weight: 600;
  letter-spacing: 0.3px;
  margin-left: 2rem;
  text-align: left;
  border-left: 1px solid transparent;
}
</style>